@import '../../../scss/animations';

.BackButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  img {
    transform: translateX(-2px);
    height: 24px;
    width: 24px;
  }
  &:global {
    &:hover img {
      cursor: pointer;
      animation: 2s arrow-bounce-left infinite;
    }
  }
}

.BackButtonSimple {
  height: 18px;
  width: 18px;
  cursor: pointer;
  img {
    transform: translateX(-3px);
    height: 100%;
    width: 100%;
  }
  &:global {
    &:hover img {
      animation: 2s arrow-bounce-left infinite;
    }
  }
}
