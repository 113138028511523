.sendbird-text-message-item-body {
  background-color: #6586ff !important;
}
.sendbird-conversation {
  border: none;
}
.sendbird-separator {
  margin-top: 30px;
}

.sendbird-text-message-item-body {
  background-color: #6586ff !important;
}
.sendbird-conversation {
  border: none !important;
}
.sendbird-message-input-text-field {
  border: 1px solid #eee !important;
}
.sendbird-open-channel-list,
.sendbird-open-channel-list-ui {
  width: 100% !important;
}
.sendbird-channel-list {
  width: 90% !important;
  height: auto !important;
}
.sendbird-conversation {
  height: 78%;
  border-left: 1px solid #ebebeb !important;
  border-bottom: 1px solid #ebebeb !important;
  border-top: none !important;
  border-right: none !important;
}
.sendbird-channel-settings {
  width: 100% !important;
}
