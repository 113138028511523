.wrapper {
  width: 100%;
  min-height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  :global {
    .bn-btn {
      width: 280px;
      margin-top: 30px;
    }
  }
}

.circle {
  border-radius: 50%;
  overflow: hidden;
}

.originalImage {
  width: auto;
  max-width: 100%;
  img {
    width: 100%;
  }
}

.croppedImage {
  border: 1px dashed hsla(0, 0%, 0%, 0.5);
  width: auto;
  max-width: 100%;
  img {
    width: 100%;
  }
}

.imageTitle {
  font-size: 16px;
}
