@use 'legacy/common/styles/colors';

@import '../../../scss/variables';

.collapsed:not(.disabled) {
  display: none !important;
}

.datepickerError {
  input {
    border: 2px solid red !important;
  }
}

.datePickerInput {
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 14px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  box-sizing: border-box;
}

.datepickerErrorMessage {
  color: red;
  width: 100%;
  text-align: right;
}

.dropdown {
  &:disabled {
    background-color: hsla(0, 0%, 0%, 0.1) !important;
  }
}

.datepickerField {
  height: 100%;
  width: 100%;

  input {
    padding: 0 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 2px #d6e3ff;

    color: #3a3d65;
    &:disabled {
      background-color: hsla(0, 0%, 0%, 0.1) !important;
    }
  }
}

.timepickerField {
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  background-color: #f8f8fb;
  border: solid 2px #d6e3ff;
  color: #3a3d65;
  &:disabled {
    background-color: hsla(0, 0%, 0%, 0.1) !important;
  }
}

.calender {
  position: absolute;
  background: white;
  z-index: 2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.popper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 0 !important;
  border-radius: 5px;
  div {
    border: none;
    background-color: white;
    font-weight: normal;
    border-radius: 5px;
  }
}

.divider {
  height: 100%;
  width: 3px;
  background: white;
}
.addRemove {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: colors.$primary;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 7px;
  transition: all ease 0.3s;
  color: white;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

.input:not(.disabled) input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0 !important;
}

.captionDropdown {
  // font-size: 12px !important;
  padding-top: 6px !important;
  padding-left: 10px !important;
  padding-bottom: 6px !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 5px !important;
}

.field {
  display: flex;
  align-items: stretch;
  height: 100%;

  &.hasError {
    > div,
    input,
    textarea {
      border-color: red !important;
    }
  }
}

.fieldContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.error {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
  color: red;
  text-align: right;
}

.dropdownError {
  border: 2px solid red !important;
}
