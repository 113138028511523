.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  overflow: auto;
  :global {
    .bn-btn {
      margin-top: 20px;
      &:last-of-type {
        margin-top: auto;
      }
    }
    .money {
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      span {
        font-size: 30px;
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }
}

.loading {
  padding: 100px !important;
}
