@import '../../../scss/variables';

.popupMessage {
  font-size: 12px;

  .link {
    color: #ffffff;
  }
}

.CreateConnect {
  .messageContainer {
    margin-bottom: 20px;

    p {
      font-size: 12px;
      text-transform: none;
    }
  }

  > div {
    margin-bottom: 10px;
  }

  :global {
    h2 > i {
      margin-left: 10px;
      cursor: pointer;
    }
    input,
    select {
      font-size: 12px;
      margin-bottom: 0;
    }
    .bn-btn {
      margin-bottom: 16px;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }

    .divider {
      padding: 5px 0;
      border-bottom: 1px solid $light-border-color;
      margin: 10px 0;
    }

    .agreement {
      text-align: center;

      margin-bottom: 5%;
    }

    label[for='firstName'] {
      margin-top: 20px;
    }
  }
}

.fieldLabel {
  color: #3a3d65;
  margin-bottom: 8px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold !important;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
