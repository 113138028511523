@import '../../../scss/mixins';

.modalBase {
  padding-right: 0;
  padding-left: 0;
}

.QuickViewProfile {
  width: 100%;
  padding: 0 !important;
  .name {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    &:hover {
      cursor: pointer;
    }
  }
  .coverPhoto {
    height: 130px;
    margin-top: -20px;
    width: 100%;
    background: linear-gradient(269.62deg, #cae3ff 0.1%, #d7d3ff 100%);
  }
  .profilePic {
    position: relative;
    margin-top: -75px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: solid 2px white;
    overflow: hidden;
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.1);
    img {
      width: 100%;
      height: 100%;
    }
    &:hover {
      cursor: pointer;
    }
    &:hover .uploadOverlay {
      opacity: 1;
    }
    @include tablet-portrait-up {
      width: 125px;
      height: 125px;
    }
    @include desktop-up {
      width: 100px;
      height: 100px;
    }
  }
  .userInfoRow {
    display: flex;
    margin-bottom: 15px;
    span {
      color: grey;

      &:not(:first-of-type) {
        margin-left: 5px;
      }
    }
  }
  .socialIconsRow {
    display: flex;
    margin-bottom: 10px;
    .socialLink {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:first-of-type) {
        margin-left: 10px;
      }
      cursor: pointer;

      img {
        height: 55%;
      }

      i {
        font-size: 16px;
      }
    }
  }
  .actionbtnRow {
    display: flex;
    .actionButton {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;

      &:not(:first-of-type) {
        margin-left: 12px;
      }

      i {
        margin-right: 10px;
      }
      .pendingRequestIcon {
        height: 20px;
        margin-right: 5px;
        margin-bottom: 3px;
      }
      .transferIcon {
        height: 20px;
        margin-right: 8px;
        margin-bottom: 3px;
      }
      .expandDropdownIcon {
        height: 23px;
        padding-top: 2px;
        margin-left: 6px;
      }
    }
  }
  .interests {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 var(--secondary-horizontal-padding);

    overflow-y: auto;

    .interest {
      background-color: #eaeaec;
      border-radius: 4px;
      padding: 7px 14px;

      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }
  .quickViewRowsContainer {
    margin-top: 25px;
    width: 100%;
    .quickViewRow {
      display: flex;
      padding: 7px 5%;
      height: 10%;

      &:hover {
        cursor: pointer;
        background-color: #f5f5f5;
      }
      .avatarGroupContainer {
        margin-top: 7px;
        margin-left: 1%;
      }
      .multipleAvatars {
        margin-left: -2.6%;
      }
      .circle {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: solid 2px #d4d4d4;
      }
      .icon {
        margin: 10px;
        height: 24px;
        width: 24px;
      }
      .contentContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-left: 15px;
        h2 {
          margin-bottom: 3px;
          font-size: 15px;
          font-weight: 600;
        }
        p {
          margin: 0;
          color: grey !important;
          font-size: 13.5px;
        }
      }
    }
    .galleryContainer {
      height: 150px;
    }
  }

  .galleryWrapper {
    width: 100%;
    height: 100%;
    padding: 0 var(--secondary-horizontal-padding);
  }
  .gallery {
    height: 100%;
    border: solid 1px #d4d4d4;
    border-radius: 4px;
    padding: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;

    .galleryPic {
      padding-top: 55%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f2f2f2;

      background-size: cover;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.loading {
  padding: 100px !important;
}
