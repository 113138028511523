.wrapper {
  position: relative;

  width: 100%;
  height: 100%;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayWrapper {
  width: 100%;
  height: 100%;
  min-height: inherit;
  position: relative;
}
