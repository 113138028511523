@import '../../../scss/variables';
@import '../../../scss/animations';

.PrivateHeader {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  align-items: center;

  background-size: cover;
  height: 50px;

  .header {
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 992px) {
      justify-content: flex-start;
      padding: 0 !important;
    }
  }

  .logo {
    position: relative;
    height: 100%;
    // background-image: url('/img/privateLogo.png');
    // background-size: cover;
    // background-position-x: -100px;
    width: 10vw;
    min-width: 180px;
    max-width: 248px;
    img {
      position: relative;
      height: 100%;
      left: -165px;
      max-width: none;
    }
    .clickWrapper {
      width: 140px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;
    }
  }

  :global {
    .links {
      margin-left: 30px;
      z-index: 1;
      display: flex;
      color: white;
      height: 100%;
      align-items: center;
      .nav {
        margin-left: 20px;
        &.active {
          font-weight: bold;
        }
        &:first-of-type {
          margin-left: 0px;
        }
        cursor: pointer;
      }

      #underline {
        height: 2px;
        width: 0px;
        background: white;
        position: absolute;
        top: 35px;
        transition: left ease 0.4s, width ease 0.35s 0.05s;
      }
    }
    .hover .icon {
      transform: rotate(3deg);
      color: rgba(255, 255, 255, 0.7);
    }
    .notifications {
      margin-right: 20px;
      margin-left: auto;
      cursor: pointer;

      .dropdown-btn {
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.open {
          border-radius: 50%;
          background-color: #5fc4ff;
        }
      }

      .btn_content {
        color: white;
        font-size: 20px;
        .icon {
          transition: all 0.1s ease;
          position: relative;
          .unread {
            position: absolute;
            top: -2px;
            right: -9px;
            background: red;
            border-radius: 50%;
            height: 15px;
            width: 15px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .dropdown {
        background: white;
        border-radius: 0.5vw;
        top: 40px;
        width: 230px;
        right: 0;
        box-shadow: 0 1px 24px 0.5px rgba(0, 0, 0, 0.08);
        max-height: 60vh;
        overflow-y: auto;

        .item {
          display: flex;
          flex-direction: row;
          i {
            margin: 5px 10px 0 5px;
          }
          padding: 5px;
          border-bottom: 1px solid #ddd;
          &.unread {
            background: #e9e9f3;
          }
          &:not(.no-notifs) {
            &:hover {
              opacity: 0.7;
            }
            cursor: pointer;
          }

          line-height: 18px;
          font-size: 12px;
        }
      }
    }
    .user {
      display: flex;
      align-items: center;

      > span {
        color: white;
        margin-left: 10px;
      }
    }

    .bn-container {
      height: 100%;
      display: flex;
      align-items: center;
      min-width: $desktop-min-width;
      max-width: $desktop-max-width;
      width: 100%;
      > .bn-row {
        height: 100%;
        align-items: center;
      }
      .bn-row.bottom {
        display: flex;
      }

      &.desktop {
        margin: 0 auto;
        padding: 0 7.3vw;
        // @media only screen and (min-width: 1500px) {
        //     padding: 0 140px;
        // }
        @media only screen and (max-width: 1100px) {
          padding: 0 5vw;
        }
      }

      &:not(.desktop) {
        min-width: 100%;
        flex-direction: column;
        > .bn-row {
          padding: 0 15px;
          &:last-of-type {
            background: rgba(255, 255, 255, 0.08);
          }
          height: 40px;
          .logo {
            left: 30px;
            margin-right: auto;
          }
          .search {
            margin: 0;
            flex: 1;
            display: flex;
            max-width: calc(100% - 140px);
            > div {
              display: flex;
              min-width: 100px;
              flex: 1;
              .search-dropdown {
                max-width: 100%;
              }
              .btn {
                display: flex;
                max-width: 100%;
                font-size: 12px;
              }
            }
            .icon {
              padding: 0 8px;
            }
          }

          .links {
            display: flex;
            overflow-x: scroll;
            flex: 1;
            margin-left: 0;
            &:last-child {
              margin-left: 15px;
            }
            #underline {
              top: 70px;
            }
            &::-webkit-scrollbar {
              display: none;
            }
          }

          .notifications {
            margin: 0 10px;
          }

          .user {
            .dropdown {
              > div {
                font-size: 12px !important;
                padding: 10px;
              }
            }
          }
        }
      }
    }

    .banner {
      width: 100%;
      min-height: 30px;
      display: flex;
      justify-content: center;
      background-color: #c7daff;
      color: #5282f6;
      padding: 4px 25px 4px 10px;
      // padding-left: 10px;
      // padding-right: 25px;
      div {
        display: flex;
      }
      .banner-text {
        padding-left: 7px;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
      .fa-info-circle {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
      }

      .fa-times {
        position: absolute;
        margin-top: 6px;
        right: 10px;
        cursor: pointer;

        &:hover {
          color: red;
        }
      }

      &#connectBanner {
        background-color: #e24f4f;
        color: #4d0202;
      }

      &#bankBanner {
        background-color: #fbbd08;
        color: #644c04;
        // border-bottom: 1px solid #ce9c07;
      }

      &#successBanner {
        background-color: #64e4b6;
        color: #4d0202;
      }

      &.mobile .bn-row {
        padding: 0px 8px;
      }
    }
  }
  &:global {
    &.mobile {
      height: 80px;
      .banner {
        min-height: 40px;
      }
      .container {
        .row {
          padding: 0px 8px;
        }
      }
    }
    &.tablet {
      height: 80px;
    }
  }
}

.PublicEventHeader {
  @extend .PrivateHeader;
  :global {
    .nav i {
      transform: translateX(4px);
    }
    .nav:hover i {
      animation: arrow-bounce-right 1.8s infinite;
    }
    .bn-row {
      justify-content: space-between;
    }
    .logo img {
      z-index: -1;
    }
  }
}

.profileLink {
  padding: 0 16px;
  display: flex;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all ease 0.3s;

  :global {
    .pic {
      height: 50px !important;
      width: 50px !important;
      margin-right: 10px;
    }
  }

  .userName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 1px;
    }
    p {
      color: #8a8a8e;
      font-size: 12px;
    }
  }

  &:hover {
    opacity: 0.6;
  }
}

.userMenu {
  :global {
    .dropdown-btn {
      padding: 2px;
      transition: all ease 0.3s;
      &.open {
        border-radius: 15px;
        background-color: #5fc4ff;
      }

      &:hover {
        opacity: 0.7;
        .pic {
          transform: rotate(3deg);
        }
      }
    }

    .btn_content {
      transition: all ease 0.3s;
      display: flex;
      align-items: center;
      cursor: pointer;

      > span {
        margin: 0 10px;
        color: white;
      }
    }

    .pic {
      transition: all ease 0.3s;
      border-radius: 50%;
      overflow: hidden;
      height: 30px;
      width: 30px;
      img {
        width: 100%;
      }
    }
    .dropdown {
      padding: 16px 0;
      background: white;
      border-radius: 0.5vw;
      top: 40px;
      width: 230px;
      right: 0;
      box-shadow: 0 1px 24px 0.5px rgba(0, 0, 0, 0.08);
    }
  }
}

.menuLink,
.createItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 8px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover {
    background-color: #bed0fa69;
  }

  i:first-of-type {
    font-size: 16px;
    width: 20px;
  }

  i:last-of-type {
    font-size: 12px;
  }

  span {
    margin-right: auto;
    margin-left: 10px;
  }
}

.organizations {
  margin-top: 10px;

  .organization {
    border: solid 1px #d4d4d4;
    height: 46px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0 10px;
    cursor: pointer;
    transition: all ease 0.3s;
    margin-top: 10px;

    &:hover {
      border-color: #5282f6;
    }
  }
}

.notificationDropdown {
  :global .dropdown {
    width: 400px !important;

    @media (max-width: 640px) {
      position: fixed !important;
      width: 100% !important;
      left: 0 !important;
      top: 84px !important;
    }
  }
}
