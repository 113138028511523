@import '../../../scss/variables';

.AddBank {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .find_bank_numbers {
    text-decoration: underline;
  }
  :global {
    form {
      height: 100%;
      margin-bottom: 16px;
    }
    .bn-btn.primary {
      margin-top: 0 !important;
    }
    .bn-btn.secondary {
      margin-top: 41px;
    }
    .col {
      display: flex;
      flex-direction: row;
      width: 100%;
      &:first-of-type {
        margin-right: 10px;
      }
    }
    .row {
      // height: 40px;
      display: flex;
      margin-bottom: 10px;
      i {
        line-height: 1.4285em;
        margin-left: 5px;
      }
    }
    input {
      width: 100% !important;
      &[name='country'] {
        width: 80px !important;
      }
      &[name='currency'] {
        width: 70px !important;
      }
    }
    .or {
      display: flex;
      height: 17px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      margin-top: 15px;
      width: 100%;
      span {
        flex: 1;
        height: 0;
        border: solid 1px rgb(228, 228, 228);
      }
      div {
        padding: 5px;
        color: grey;
        font-size: 10px;
        font-weight: 100;
      }
    }
    div.inputTitleBox {
      display: flex;
      // height: 100%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: white;
      border: solid 1px #dcdcdd;
      border-right: none;
      flex-basis: auto;
      padding-left: 7px;
      padding-right: 7px;
      white-space: nowrap;
      .inputTitle {
        color: rgb(126, 126, 126);
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    .withTitle {
      border-left: none !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .defaultBank {
      margin-top: 10px;
    }
    .findBankNumbersButton {
      text-decoration: underline;
      cursor: pointer;
      margin-left: auto;
      color: $bounce-blue;
    }
  }
  &:global {
    &.mobile {
      .withTitle {
        border: solid 1px #dcdcdd !important;
        border-radius: 4px !important;
      }
    }
  }
}

.FindBankNumbers {
  img {
    width: 100% !important;
    margin-bottom: 30px !important;
  }
  h2:first-of-type {
    margin-top: 50px;
  }
}

.plaidButton {
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  button {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.message {
  margin-top: 10px;
  line-height: 1;
  font-size: 11px;
  color: hsla(0, 0%, 0%, 0.6);
  font-weight: 400;
}

.addBankForm {
  width: 80%;
}
