.InviteFriends {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 80%;
  :global {
    .bn-btn {
      width: 100%;
      margin-bottom: 20px;
      &.primary {
        margin: 0;
        margin-top: auto !important;
      }
    }
  }
}

.inputWrapper {
  width: 100%;
  position: relative;
}

.toggle {
  display: flex;
  margin-bottom: 6px;

  div {
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    color: #3a3d65;
    border: 2px solid #3a3d65;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.active {
      background-color: #3a3d65;
      color: white;
    }
  }
}

.inputRow {
  display: flex;
  margin-bottom: 18px;

  input {
    flex: 1;
    margin-bottom: 0;
  }
}

.remove {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  font-size: 12px;
  width: 36px;
  cursor: pointer;
}
