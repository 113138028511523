.ExportGuests {
  :global {
    .fas {
      margin-top: 16px;
      font-size: 28px;
      margin-bottom: 16px;
      color: hsl(203, 22%, 54%);
    }
  }
}

.download {
  margin-top: auto;
}

.grid {
  width: 100%;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 50px;
  grid-column-gap: 10px;
  grid-row-gap: 16px;
}

.sectionLabel {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  color: hsl(203, 22%, 54%);
  text-align: left;
  letter-spacing: 0.05em;
}
