.wrapper {
  width: 100%;
  max-height: 100%;
  padding: 14px !important;

  :global {
    .footer {
      margin-top: 24px;
      width: 100%;
      p.guestNumber {
        font-size: 13px !important;
        text-align: center;
      }
    }
    .search {
      width: 95%;
      display: flex;
      border-bottom: solid 1px #3a3d65;
      margin-bottom: 16px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      input {
        font-size: 12px !important;
        background: none !important;
        border: 0 !important;
        margin: 0 !important;
      }
    }
  }
}

.guestList {
  width: 100%;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  height: 270px;
  padding-right: 20px;
  margin-right: -20px;
}

.loading {
  display: flex;
  justify-content: center;
}

.noSearchResults {
  text-align: center;
}

.person {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  transition: all 0.2s ease;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;

  padding: 4px 0;

  > i {
    margin-left: auto;
    font-size: 16px;
  }
  :global {
    .details {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .pic {
      display: flex;
      align-items: center;
      overflow: hidden;
      justify-content: center;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      margin-right: 10px;
      background: #3a3d65;
      img {
        margin: 0;
        height: 100%;
      }
      i {
        color: white;
      }
    }
  }
}

.complimentaryGuest {
  cursor: default;
}

.guestInfo {
  width: 140px;
  display: flex;
  flex-direction: column;
}

.amount {
  display: flex;
}

.pendingAmount {
  color: rgb(253, 173, 0);
}

.error {
  color: red;
}
