.TransferConfirmation {
  :global {
    img {
      margin-top: 20px;
      width: 100% !important;
    }
    .primary {
      margin-top: auto;
    }
  }
}
