.Card {
  position: relative;
  font-size: 1.3vw;
  width: 18vw;

  overflow: hidden;
  position: relative;

  background-color: #2d2d2d;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  margin: 10px;
  border-radius: 0.7vw;
  &:last-of-type {
    margin-right: 0;
  }

  :global {
    .wrapper {
      width: 100%;
      padding-top: 65%;
    }

    .abs-content {
      position: absolute;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .card-type {
      position: absolute;
      top: 10px;
      left: 10px;
      background: #eeeeee;
      color: #3a3d65 !important;
      border-radius: 4px;
      padding: 4px;
      font-weight: bold;
      font-size: 12px;
      cursor: default;
      &.alt {
        cursor: pointer;
        opacity: 0.8;
      }
      &.default {
        background: #3b3b3b;
        color: #d5d5d5 !important;
      }
    }

    .type {
      color: #2d2d2d;
      position: absolute;
      top: 5%;
      right: 5%;
      display: flex;
      justify-content: flex-end;
      i {
        line-height: 20px;
        font-size: 26px;
        background: #d5d5d5;
        border-radius: 0.5vw;
        border: solid 1px #050404;
      }
    }
    .number {
      color: #d5d5d5;
      transition: all ease 0.3s;

      width: 87%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      div {
        display: flex;
        align-items: center;
      }
    }

    .bn-row.info {
      width: 87%;

      transition: all ease 0.3s;
      margin-top: 30%;
      color: #d5d5d5;

      font-size: 10px;
      justify-content: flex-end;
    }

    .buttons.actions {
      background: #484848;
      transition: all ease 0.3s;
      position: absolute;
      height: 36%;
      top: 86%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding-top: 10%;
      img {
        transition: all ease 0.3s;
        margin: 0 8%;
        height: 60%;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &:global {
    &.enable-hover {
      &:hover {
        .card-type.alt:hover {
          opacity: 1;
        }
        .info {
          margin-top: 10%;
        }
        .buttons {
          padding: 0;
          top: 64%;
        }
      }
    }
  }
}

.mainContent {
  color: #d5d5d5;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
