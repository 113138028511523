.ViewHosts {
  width: 100%;
  padding-top: 40px;

  > div {
    width: 85%;
    margin-bottom: 30px;
  }

  :global {
    h2 {
      margin-bottom: 20px !important;
      width: 85%;
    }

    .operators {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        margin-bottom: 13px;
      }
    }
    .person {
      display: flex;
      align-items: center;
      &:last-of-type {
        margin-right: auto;
      }
      margin-bottom: 5px;
      transition: all 0.2s ease;
      width: 100%;
      cursor: pointer;
      &:hover {
        background: #f8f8f8;
      }
      > i {
        margin-left: auto;
        font-size: 16px;
      }
      :global {
        .pic {
          display: flex;
          align-items: center;
          overflow: hidden;
          justify-content: center;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          margin-right: 10px;
          background: #3a3d65;
          img {
            margin: 0;
            height: 100%;
          }
          i {
            color: white;
          }
        }
      }
    }
    .name {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
