.wrapper {
  :global {
    .img-wrapper {
      margin-top: 20px;
      width: 100%;
      margin-bottom: 10px;
    }
    .fill-vertical {
      flex: 1;
    }
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.form {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message {
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.success {
  @extend .form;
}
