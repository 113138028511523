.wrapper {
  --tier-padding: 10px;
  padding: 10px !important;
  h1 {
    margin-top: 20px;
  }
}

.baseTier {
  padding: var(--tier-padding);
}

.tier {
  margin-bottom: 6px;
  position: relative;

  // pulled from CreateTier.module.scss / .tier
  width: 100%;
  margin-bottom: 16px;
  border-radius: 4px;
  border: solid 2px #e2e2e2;
}

.infoRow {
  padding: var(--tier-padding);
  position: relative;
  display: flex;
  align-items: center;

  span {
    margin-right: 12px;

    &.name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  div {
    margin-left: auto;
    color: #6386fe;
    min-width: 62px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.tokenRow {
  display: flex;
  padding: 0 12px;
  height: 32px;
  align-items: center;
  font-weight: bold;
}

.lock {
  // height: 18px;
  // position: absolute;
  // top: 6px;
  // right: -22px;

  height: 14px;
  margin-left: 10px;
}
