.fadeIn {
  animation: fade-in 350ms ease;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
