.wrapper {
  padding-top: 50px !important;
}

.request {
  padding: 6px 10px;
  border-radius: 4px;
  border: solid 2px #d6e3ff;
  background-color: #f8f8fb;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.title {
  font-weight: bold;
  margin-bottom: 8px;
  width: 100%;
  margin-top: 20px;
}

.answer {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  align-items: baseline;
}

.note {
  max-width: 80%;
  flex: 2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.name {
  flex: 1;
  font-weight: bold;
  text-align: right;
}
