.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
}

.error {
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;

  // Hide unless error exists
  opacity: 0;
  &.visible {
    opacity: 1;
  }
}
