.InviteUsers {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: center;
  height: 100%;

  :global {
    .search {
      width: 85%;
      display: flex;
      border-bottom: solid 1px #3a3d65;
      margin-bottom: 16px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      input {
        background: none !important;
        border: 0 !important;
        margin: 0 !important;
      }
    }

    .users {
      overflow: auto;
      overflow-y: scroll;
      height: 70%;
      width: 100%;
    }

    .bn-btn {
      margin-top: auto;
      margin-bottom: 10px;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
}

.disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.45;
}

.noSearchResults {
  text-align: center;
}

.user {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .profilePic {
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background: #3a3d65;
    img {
      margin: 0;
      height: 100%;
    }
    i {
      color: white;
    }
  }

  .statusLabel {
    opacity: 0.6;
  }

  &:hover {
    background: #f8f8f8;
  }

  > i {
    margin-left: auto;
    font-size: 16px;
  }
}
