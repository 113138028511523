.Spinner {
  // border-radius: 50%;
  // overflow: hidden;
  // background: linear-gradient(to right, #6ddaf3 0%, #924df9 100%);
  :global {
    i {
      // mix-blend-mode: screen;
      // color: black;
      // background: white;

      color: #6ddaf3; // fall-back for non webkit browsers

      background: linear-gradient(to right, #6ddaf3 0%, #924df9 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      animation: rotate cubic-bezier(0.64, 0.1, 0.26, 0.93) infinite 2s;
    }
  }
}
