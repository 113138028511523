@import '../../../scss/mixins';
//@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

.Ticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  :global {
    .col {
      margin: 0;
    }
    .bn-btn {
      margin-top: auto;
      margin-bottom: 10px;
      width: 100%;
    }
    .calendarContainer {
      &:hover {
        cursor: pointer;
      }
      position: absolute;
      right: 0;
      font-weight: 600;
    }
    .dropdown {
      position: absolute;
      border-radius: 10px;
      display: block;
      flex-direction: column;
      top: 30px;
      left: -45px;
      width: 180px;
      padding: 8px 0px 8px 0px;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      text-align: left;
      ul {
        list-style: none;
        margin: 0;
        padding-left: 0px;
        li {
          font-size: 12px;
          height: 32px;
          transition: all ease 0.3s;
          &:hover {
            cursor: pointer;
            background: #ddd;
          }
          a {
            padding: 5px 15px;
            display: block;
            color: #3a3d65;
            text-decoration: none;
            width: 100%;
            text-align: left;
            .fa-google:before {
              font-family: 'Font Awesome 5 Brands';
              content: '\f1a0';
            }
            .fa-windows:before {
              font-family: 'Font Awesome 5 Brands';
              content: '\f17a';
            }
            .fa-apple:before {
              font-family: 'Font Awesome 5 Brands';
              content: '\f179';
            }
            .fa-yahoo:before {
              font-family: 'Font Awesome 5 Brands';
              content: '\f19e';
            }
            i {
              padding-right: 20px;
            }
          }
        }
      }
    }
    .not-bounced {
      margin-top: 20px;
    }
    .ticket-wrapper {
      margin-top: 10px;
      padding: 5px;
      width: 90%;
      background: hsl(226, 70%, 98%);
      border-radius: 5px;
      @include box-shadow-2;
    }
  }
}

.eventDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.qrSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventDetailsContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.sectionLabel {
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  letter-spacing: -0.5px;
}

.date {
  height: 100%;
  flex: 1;
}

.address {
  height: 100%;
  flex: 1;
  text-align: left;
}
