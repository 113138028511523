@use 'legacy/common/styles/config';

.container {
  position: fixed;
  top: 0;
  z-index: 999;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
