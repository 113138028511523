@use 'legacy/common/styles/config.scss';
@use 'legacy/common/styles/colors.scss';

.wrapper {
  width: 100%;
  height: 100%;
  background: white;
}

.background {
  width: 100%;
  min-height: 100%;

  position: relative;
  padding-bottom: 130px;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  background-color: white;
  background-size: auto 130px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url('/img/backgrounds/login_bg.svg');

  @media (min-width: config.$md) {
    padding-top: 10px;
    padding-bottom: 70px;

    align-items: center;
    background-position: bottom -40px center;
    background-size: auto;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  height: 50px;

  padding: 10px;

  display: flex;
  align-items: center;
  flex-direction: column-reverse;

  .logo {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-bottom: 10px;

    font-size: 12px;
    font-weight: 600;
    color: colors.$white;

    img {
      cursor: pointer;

      margin-bottom: 10px;
    }
  }

  .links {
    margin: 0 0 20px 0;
    padding: 0;

    list-style: none;

    li {
      display: inline-block;
      margin-right: 10px;

      a {
        font-size: 12px;
        font-weight: 600;

        color: colors.$white;
      }
    }
  }

  @media (min-width: config.$md) {
    padding: 0;

    flex-direction: row;
    align-items: flex-start;

    .logo {
      flex-direction: row;

      margin-bottom: 0;

      img {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    .links {
      margin: 0;

      li {
        margin-bottom: 0px;
        margin-left: 32px;
      }

      .logout {
        display: none;
      }
    }
  }

  @media (min-width: config.$xll) {
    margin-bottom: 35px;
  }
}
