@use 'legacy/common/styles/colors';

.wrapper {
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  background-color: colors.$white;
  border: solid 2px #d6e3ff;
  color: #3a3d65;
  margin-bottom: 18px;
  font-size: 12px;

  &:disabled {
    cursor: not-allowed;
    background-color: hsla(0, 0%, 0%, 0.1) !important;
  }
}
