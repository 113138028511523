@use 'legacy/common/styles/colors';

.backdrop {
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100vh;
  background: colors.$black;
  opacity: 0.4;

  z-index: 1;
}
