html,
body {
  height: 100%;
  margin: 0;
  width: 100%;
}

#__next {
  width: 100%;
  height: 100%;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
