$primary: #6586ff;
$primary-200: #f0f3ff;
$primary-300: #EFF2FF;
$primary-500: #4a70ff;
$primary-600: #567ffe;
$primary-700: #3853C4;
$primary-800: #262A36;
$border: #dcdcdd;

$error: #ab0a0a;
$success: #61d345;
$warning: #f2500a;

$facebook: #3d5196;

$white: #fff;
$black: #000;

$gray-900: #2A2A2A;
$gray-500: #464646;

$gray-300: #6b6b6b;
$gray-200: #a2a2a2;
$gray-100: #e5e5e5;
$gray-50: #f3f3f3;

$gray: #979797;
$gray-dark: #333333;
$gray-light: #b4b4b4;
$danger: #ab0a0a;
