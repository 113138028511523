@use 'legacy/common/styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: center;
  height: 100%;
  :global {
    .search {
      width: 85%;
      display: flex;
      border-bottom: solid 1px #3a3d65;
      margin-bottom: 16px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      input {
        background: none !important;
        border: 0 !important;
        margin: 0 !important;
      }
    }
    .friends {
      overflow-x: hidden;
      overflow-y: auto;
      height: 80%;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .bn-btn {
      margin-top: auto;
      margin-bottom: 10px;
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    background: #f8f8f8;
    transform: translateX(3px);
  }
  > i {
    margin-left: auto;
    font-size: 16px;
  }
  :global {
    .pic {
      display: flex;
      align-items: center;
      overflow: hidden;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 10px;
      background: #3a3d65;
      img {
        margin: 0;
        height: 100%;
      }
      i {
        color: white;
      }
    }
  }
}

.title {
  margin: 10px 0;
}

.selectedUsers {
  overflow-x: hidden;
  overflow-y: auto;
  height: 40%;
  width: 100%;
  padding: 10px;
  border: 1px solid colors.$border;
  border-radius: 10px;
}
