.ChoosePaymentMethod {
  :global {
    .cards {
      width: 100%;

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      display: flex;
      flex-direction: column;
      margin: 0.5vw;
      > div > div {
        opacity: 0.7;
      }
      div.active {
        > div {
          box-shadow: rgba(155, 171, 214, 0.42) 0px 1px 24px 0.5px;
          opacity: 1;
        }
      }
    }
    .bn-btn {
      margin-top: auto;
    }
  }
}
