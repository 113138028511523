.JoinEvent {
  // display: flex;
  // flex-direction: column;

  > img {
    width: 90% !important;
  }

  :global {
    .bn-btn.plain {
      margin-bottom: 10px;
      margin-top: auto;
    }
    .bn-btn.primary {
      margin-top: auto;
    }
    .card {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .modalTitle {
      margin-top: 20px;
      width: 90% !important;
    }
    .congrats {
      margin-bottom: 10px;
    }
    .spinner {
      margin-top: 200px;
    }
    .googleFormsLink {
      font-size: 12.5px !important;
      text-align: center;
      padding-bottom: 5px;
    }
    .buttonRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .alreadyCompletedButton {
        width: 40% !important;
        margin-right: 10px;
      }
    }
    iframe {
      width: 100%;
      flex: 1 1;
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }
}

.eventDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionLabel {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  color: hsl(203, 22%, 54%);
  text-align: center;
  letter-spacing: 0.05em;
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .day {
    font-weight: bold;
  }
}

.date {
  height: 100%;
  flex: 1;
  border-right: 1px solid hsla(0, 0%, 0%, 0.1);
}

.address {
  height: 100%;
  flex: 1;
  text-align: center;
}

.pricingBreakdown {
  width: 80%;
}

.pricingItem {
  &:nth-child(1) {
    margin-bottom: 5px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  span:nth-child(1) {
    text-align: left;
    font-weight: 400;
  }
  span:nth-child(2) {
    text-align: right;
    font-weight: 700;
  }

  &.total {
    span:nth-child(1) {
      font-size: 14px;
      letter-spacing: 0.03em;
    }
    span:nth-child(2) {
      font-size: 20px;
      line-height: 20px;
      font-weight: 600;
      color: #419ad2;
      letter-spacing: -0.05em;
    }
  }
}

// .tier {
//     min-height: 40px;
//     height: auto;
// }

.tier.hasTokenError {
  border-color: red !important;
}

.tokenError {
  color: red !important;
  margin-top: -10px;
}

.baseTier {
  margin-top: 16px;
}

.tier.selectedTier {
  border: solid 2px #6386fe;
  color: #6386fe;
}

.tier .innerTier {
  position: relative;
  flex-direction: column;
  justify-content: center;
  min-height: 40px;
  height: auto;
  padding-top: 6px;
  padding-bottom: 6px;

  > div {
    align-items: center;
  }

  input {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: solid 2px #e2e2e2;
    background-color: #f7f7f8;
    height: 30px;
    width: 80%;
    align-self: flex-start;
    padding: 0 12px;
    flex: 1;
  }

  .lock {
    margin-left: auto;
    margin-right: 0;
    height: 12px;
  }
}

.errMsg {
  color: red;
  margin-bottom: 10px;
}

.privateRow {
  margin-top: 10px;
  display: flex;
}

.enterCode {
  // border-radius: 4px;
  position: relative;
  margin-left: 10px;
  height: 30px !important;
  min-height: 30px !important;
  padding: 0 12px;
  width: 30% !important;
  // display: flex;
  &.verified {
    border: solid 2px #32a827;
    color: #32a827;
  }
}

.remaining {
  margin-left: auto;
  font-weight: bold;
  margin-right: 0 !important;
}

.soldOut {
  background-color: #f7f7f8;
  color: #313131;
  cursor: default;
}

.disabledButton {
  cursor: not-allowed;
  opacity: 0.6;
}

.generalTier {
  margin-top: 16px;
}

.tierCol {
  display: flex;
  flex-direction: column;

  > span:nth-child(2) {
    color: rgb(173, 173, 173);
  }
}

.tip {
  margin-top: 16px;
  width: 100%;

  > div {
    width: 100%;

    > div:first-of-type {
      flex: 1;

      > div {
        width: 100%;
      }
    }
  }
}

.loading {
  padding-top: 100px !important;
}
