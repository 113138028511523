.AddPhone {
  :global {
    .info {
      margin-bottom: 20px !important;
    }
    #phone {
      padding-left: 50px !important;
    }
    img {
      width: 90% !important;
    }
    .primary {
      margin-top: auto;
    }
    .plain {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.codeInput {
  font-size: 28px;
  margin-top: 10px;
  label {
    font-size: 14px;
    line-height: 1.5;
    // margin-bottom: 10px;
    font-weight: 600;
  }
}

.resendVerification {
  margin-top: auto;
  &:global(.bn-btn) {
    width: 100%;
  }
}

.phoneInput {
  padding-right: 6px !important;
  padding-left: 52px !important;
  margin-left: 0 !important;
  font-size: 14px !important;
  border: 1px solid black;
}

.error {
  color: red;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 12px;

  // Hide unless error exists
  opacity: 0;
  &.visible {
    opacity: 1;
  }
}
