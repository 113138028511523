.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes arrow-bounce-right {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(4px);
  }
  40% {
    transform: translateX(0px);
  }
  60% {
    transform: translateX(2px);
  }
}

@keyframes arrow-bounce-left {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(-3px);
  }
  40% {
    transform: translateX(0px);
  }
  60% {
    transform: translateX(-1.5px);
  }
}
