@use 'legacy/common/styles/config';
@use 'legacy/common/styles/colors';

.mobileSearch {
  z-index: 999;
  padding: 10px;
  margin-right: -10px;

  @media (min-width: config.$md) {
    display: none;
  }
}

.wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 10px 0;

  background-color: colors.$white;

  z-index: 999;

  @media (min-width: config.$md) {
    display: block;

    position: relative;
    width: auto;
    height: auto;
    padding: 0;

    background: none;
  }
}

.searchContainer {
  display: flex;
  padding: 20px;

  .back {
    flex-shrink: 0;
    margin-right: 15px;

    @media (min-width: config.$md) {
      display: none;
    }
  }

  .search {
    height: 40px;
    flex-grow: 1;

    position: relative;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid colors.$gray;
    border-radius: 15px;

    background-color: colors.$white;

    img {
      width: 12px;
      margin: 0 8px;
    }

    .input {
      width: 100%;
      height: 40px;
      font-size: 16px;

      margin: 0;

      border: none;
      background: none;

      &::placeholder {
        opacity: 0.4;
        font-style: italic;
        color: colors.$gray-dark;
      }
    }

    .close {
      width: 16px;
      height: 16px;

      padding: 3px;
      cursor: pointer;
    }
  }

  @media (min-width: config.$md) {
    padding: 0;

    .search {
      height: auto;

      width: 320px;
      height: 38px;

      border: none;
      border-radius: 13px;

      .input {
        font-size: 12px;
      }

      &.active {
        border-radius: 13px 13px 0 0;
      }
    }
  }
}
