.SendMoney {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  overflow: auto;
  h2 {
    margin-bottom: 5px !important;
  }
  :global {
    div.info {
      margin-bottom: 5px;
      margin-top: 0px;
      font-size: 10px;
      font-weight: lighter;
      text-align: center;
      line-height: 1.2em;
      color: #6d6d6d;
    }
    .bn-btn.submit {
      margin-top: auto;
    }
    .bn-btn.change-card {
      margin-top: 10px;
    }
    .quickSendWrapper {
      overflow-x: auto;
      overflow-y: visible;
      &::-webkit-scrollbar {
        display: none;
      }
      display: flex;
      justify-content: flex-start;
      padding: 10px 0px;
      width: 100%;
      flex-wrap: nowrap;
    }

    .private {
      margin-top: 15px;
      p {
        margin-left: 15px;
      }
    }
    .money {
      justify-content: center;
      align-items: center;
      span {
        font-size: 30px;
        font-weight: bold;
        margin-right: 10px;
      }
      .wrapper {
        width: 125px;
        text-align: center;
      }
    }
    input[name='price'] {
      width: 100%;
      text-align: center;
      background: none;
      border: 0;
      font-size: 30px !important;
      font-weight: bold;
      border-bottom: solid black 2px;
      border-radius: 0;
      margin: 0;
      padding: 0;
    }
    textarea {
      line-height: 18px;
      background: #f8f8fb;
      width: 100%;
      border: solid 2px #d6e3ff;
      border-radius: 4px;
      border-top-left-radius: 0;
      padding: 10px;
      &:disabled {
        opacity: 0.45;
      }
    }
  }
}
