.modal {
  border: none !important;
  padding: 0 !important;
}

.wrapper {
  background: white;
}

.pic {
  padding-top: 55%;
  width: 80vw;
  position: relative;

  > div {
    background-size: cover;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.btn {
  position: absolute;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.close {
  top: 10px;
  right: 10px;
}

.left {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.right {
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
