@use 'legacy/common/styles/colors';
@use 'legacy/common/styles/config.scss';

.banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 220px;

  background: colors.$primary-500;
  align-self: flex-end;

  padding: 20px;
  z-index: 999;
  margin-bottom: env(safe-area-inset-bottom);

  @media (min-width: config.$md) {
    display: none;
  }
}

.title {
  font-size: 20px;
  color: colors.$white;
}

.message {
  font-size: 14px;
  color: colors.$white;
  margin-bottom: 22px;
}

.dismiss {
  margin-top: 10px;
}
