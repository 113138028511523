.toast {
  padding: 0 !important;

  .container {
    cursor: pointer;

    display: flex;
    align-items: center;

    padding: 8px 10px;

    img {
      width: 40px;
      height: 40px;

      margin-right: 10px;

      border-radius: 100%;
    }

    .message {
      max-width: 200px;
    }
  }
}
