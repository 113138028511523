@import '../../../scss/mixins';
@import '../../../scss/variables';

.PrivatePage {
  padding-top: 60px;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'main';
  background: #ffffff;

  // &.withBanner {
  //     padding-top: 90px;
  // }

  :global {
    .bn-container {
      background-color: #fefefe;
      box-shadow: 0 1px 24px 0.5px rgba(0, 0, 0, 0.08);
      margin-bottom: 10px;
      > .title {
        border-bottom: 1px solid #f8f8fb;
        padding: 1vw;
        background: #fafbff;
      }
      .content {
        padding: 0 1vw;
      }
    }
  }
  &:global {
    &.tablet {
      padding-top: 120px;
    }
    &.mobile {
      padding-top: 130px;
    }

  }
}

.main {
  
  grid-area: 'main';
  display: flex;
  margin-bottom: 60px;
}

.pageContent {
  width: 100%;
  height: 100%;
}
