/*
- At or lower than $min-vw, the value of the property/properties is $min-value
- At or higher than $max-vw, the value is $max-value
- Between $min-vw and $max-vw, the property value increases linearly

Usage:
.yourClass {
    @include fluid-type(font-size, 400px, 1200px, 20px, 40px);
}
*/
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(
        #{$min-value} +
          #{strip-unit($max-value - $min-value)} *
          (100vw - #{$min-vw}) /
          #{strip-unit($max-vw - $min-vw)}
      );
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin small-phone-only {
  @media (max-width: 374px) {
    @content;
  }
}
@mixin medium-phone-up {
  @media (min-width: 375px) {
    @content;
  }
}
@mixin tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

$box-shadow-transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
@mixin box-shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: $box-shadow-transition;
}

@mixin box-shadow-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: $box-shadow-transition;
}
@mixin box-shadow-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: $box-shadow-transition;
}
@mixin box-shadow-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: $box-shadow-transition;
}
@mixin box-shadow-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  transition: $box-shadow-transition;
}
