@import 'variables';

.text-warning {
  color: #ecb100;
}

.text-error {
  color: #dc3545;
}

.shadow {
  box-shadow: $std-box-shadow;
}

.base {
  margin: 56px 0 32px 0;
  width: 100%;
  min-height: calc(100% - 88px);
  background-color: #fefefe;
  // box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 24px 0.5px rgba(0, 0, 0, 0.08);
}

.btn-pill {
  font-size: 16px;
  min-width: 120px;
  color: #ffffff;
  background-color: #6586ff;
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 40px;
  font-family: 'Inter', sans-serif;
  padding: 0.7em 1em;
  box-shadow: (0px 4px 50px rgba(0, 0, 0, 0.15));
  font-weight: 500;
  transition: 0.3s;
  &:hover {
    box-shadow: (0px 4px 50px rgba(0, 0, 0, 0.3));
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
}

.btn {
  cursor: pointer;
  position: relative;
}

.bn-btn-group {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  .bn-btn {
    min-width: 150px;
    flex: 1;
    // width: 45%;
  }
}

.bn-btn {
  transition: all 0.15s ease;
  position: relative;
  &.sm {
    width: 10vw;
  }
  &.md {
    width: 15vw;
  }
  &.lg {
    width: 20vw;
  }
  &:not(.disabled):not(.loading) {
    cursor: pointer;
  }
  width: 20vw;
  height: 4vw;
  max-height: 40px;
  min-height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  border: solid 2px #6386fe;
  color: #6386fe;
  background-color: white;

  &.danger {
    border: solid 2px #ff2c2c;
    color: white;
    background-color: #ff2c2c;
  }
  &.disabled {
    cursor: default;
    opacity: 0.45;
    box-shadow: none;
  }
  &.primary {
    border: 0;
    color: white;
    background-image: linear-gradient(212deg, #b721ff, #21d4fd);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &:hover:not(.disabled):not(.loading):not(.notransform) {
      transform: translateY(-1px);
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    }
    &:active:not(.disabled):not(.loading):not(.notransform) {
      transform: translateY(1px);
    }
  }
  &.fb-login {
    border: 0;
    color: white;
    background-color: #4267b2;
    min-width: 150px;
    i {
      margin-right: 11.7px;
      margin-left: 0.5em;
    }
  }
  &.sign-up {
    border: solid 2px #6386fe;
    color: #6386fe;
  }
  &.plain {
    border: 0;
    background: #f8f8fb;
    color: #3a3d65;
  }
  &.plain-bordered {
    border: solid 2px #3a3d65;
    background: none;
    // background: #f8f8fb;
    color: #3a3d65;
  }
  &.host-new-event {
    border: 0;
    color: #3a3d65;
    border-radius: 4px;
    background: white;
    width: 160px;
    max-width: 160px;
    height: 36px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.2);
    }
    &:active {
      transform: translateY(1px);
      box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.2);
    }
  }
  &.quickSend {
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 5000;
    margin: 0 8px;
    height: 30px;
    min-height: auto; //reset bn-btn style
    width: 60px !important;
    min-width: 60px;
    background: #3ecf8e;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    text-shadow: 0 1px 3px rgba(36, 180, 126, 0.4);
    border-radius: 15px;
    transition: transform 0.15s ease;
    border: none;
    font-size: 14px !important;
    color: white;
    span {
      margin-left: 1px;
    }
    &:hover,
    &:focus {
      transform: translateY(-1px);
    }
    &:active {
      transform: translateY(1px);
    }
    &::after,
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 15px;
      transition: opacity 0.15s ease;
      opacity: 0;
    }
    &::after {
      box-shadow: 0 5px 10px rgba(50, 50, 93, 0.11),
        0 3px 6px rgba(0, 0, 0, 0.08);
    }
    &::before {
      box-shadow: 0 5px 10px -2px rgba(50, 50, 93, 0.25),
        0 3px 7px -3px rgba(0, 0, 0, 0.3);
    }
    &:hover:not(:active)::after,
    &:focus:not(:active)::after {
      opacity: 1;
    }
    &:active::before {
      opacity: 1;
    }
  }
}

.section {
  margin-bottom: 1vw;
}

.btn-fade {
  transition: all ease 0.3s;
  &:hover {
    opacity: 0.7;
    filter: drop-shadow(2px 2px #ddd);
  }
}

.btn-wrapper {
  display: flex;
  width: 80%;
  justify-content: center;

  .app-store {
    height: 3.1vw;
    min-height: 40px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
  }

  .dashboard {
    max-height: unset;
    border-radius: 4px;
    border: none;
    width: 45%;
    height: 3.1vw;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #6386fe;
    text-transform: uppercase;
    font-size: 1.2vw;
    font-weight: bold;
    font-style: normal;
    line-height: 0.75;

    &:hover {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 600px) {
    .dashboard {
      font-size: 11px;
    }
  }
  @media screen and (max-width: 375px) {
    .dashboard {
      font-size: 9px;
    }
    .dashboard,
    .app-store {
      min-height: 30px;
    }
  }
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.text-warning-dark {
  color: #46330F;
}
