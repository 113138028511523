@import '../../../../../scss/variables';

.AddCard {
  width: 80%;
  height: 100%;
  :global {
    #card-cvc {
      justify-content: center;
    }
    .col {
      width: 100%;
      &:first-of-type {
        margin-right: 10px;
      }
    }
    .row {
      display: flex;
      height: 40px;
      margin-bottom: 10px;
      i {
        line-height: 1.4285em;
        margin-left: 5px;
      }
      &.defaultCard {
        margin-top: 10px;
      }
    }
    img {
      height: 22px;
      margin-right: 7px;
    }
    .name {
      margin-top: 5px;
    }
    .tabs {
      display: flex;
      margin-bottom: 20px;
      .option {
        display: flex;
        margin-left: 10px;
        &:first-of-type {
          margin-left: 0;
        }
        height: 50px;
        padding: 10px;
        border-radius: 4px;
        flex: 1 1;
        div {
          margin-top: auto;
          margin-bottom: auto;
        }
        cursor: pointer;
        img {
          height: 100%;
        }
        &.active {
          background-color: #f4f4f5;
        }
      }
    }
  }

  &:global {
    &.mobile {
      .tabs {
        .option {
          width: 50px;
          text-align: center;
          img {
            margin: 0;
          }
        }
      }
    }
    > div {
      width: 100% !important;
    }
  }
}

.externalPay {
  :global {
    background-color: #f4f4f5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 60px;
    text-align: center;
    img {
      height: 50px;
      margin-right: 0;
      margin-top: 20px;
    }
    h3 {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 400;
    }
    .inner-text {
      margin-top: 20px;
    }
    .not-supported-text {
      margin-top: 55px;
      font-weight: 700;
    }
    .secondary-link {
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 10px;
      color: $bounce-blue;
    }
  }
  &:global {
    &.mobile {
      padding: 20px;
      h3 {
        font-size: 18px;
      }
    }
  }
}

.stripeInput {
  padding-top: 10px;
}

.boxShadow {
  box-shadow: 0px 13px 68px -7px rgba(102, 133, 254, 0.69);
  -webkit-box-shadow: 0px 13px 68px -7px rgba(102, 133, 254, 0.69);
  -moz-box-shadow: 0px 13px 68px -7px rgba(102, 133, 254, 0.69);
}
