@use 'legacy/common/styles/colors';
@use 'legacy/common/styles/config';

.results {
  width: 100%;
  padding: 0 6px 20px;

  overflow: auto;
  position: relative;

  border-radius: 0 0 10px 10px;
  border-top: 1px solid colors.$primary;

  background: colors.$white;

  .tab {
    display: flex;
    padding: 4px 0px;
    margin: 0 10px;

    border-bottom: 1px solid colors.$primary;

    .category {
      padding: 10px 0;
      margin-right: 30px;

      font-size: 12px;
      color: colors.$gray;

      cursor: pointer;

      &.active {
        color: colors.$primary;
      }

      &:hover {
        color: colors.$black;
      }
    }
  }

  @media (min-width: config.$md) {
    position: absolute;
    top: 34px;
    max-height: 500px;
  }
}

.backdrop {
  width: 100%;
  height: 100vh;
  background: black;
}
