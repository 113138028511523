@import '../../../scss/variables';
@import '../../../scss/mixins';

$content-br: 6px;
$warning-border-color: #ec407a;
$delay: 350ms;
$slide-distance: 100px;

.defaultOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
}

.defaultContent {
  padding-top: 12px;
  padding-bottom: 15px;
  position: relative;
  border-top: $content-br solid $theme-color;
  outline: none !important;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: $content-br;
  width: 80%;
  &:global {
    > form,
    > div:nth-child(2):not(.custom) {
      width: 80%;
      .bn-btn {
        width: 100%;
      }
      > img {
        width: 80px;
        height: auto;
        margin-bottom: 20px;
      }
      p {
        color: #3a3d65;
        margin-bottom: 8px;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold !important;
        width: 100%;
        margin-top: 0;
      }
      .inputWrapper {
        padding-left: 7px;
        height: 40px;
        width: 100%;
        border-radius: 4px;
        background-color: white;
        border: solid 1px #dcdcdd;
        display: flex;
        align-items: center;
        > div {
          width: 100%;
        }
        span {
          opacity: 0.75;
          margin-right: 11px;
        }
      }
    }
  }
}

.back {
  position: absolute;
  top: 16px;
  left: 16px;
}

.desktop-basic {
  @extend .defaultContent;
  width: 300px;
  height: 500px;
  > div:nth-child(2):not(.custom),
  > form {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    width: 100%;
  }
}

.tablet-basic {
  @extend .desktop-basic;
}

.large-modal {
  border-top: none;
  width: 600px;
  height: 575px;
  padding: 20px;
  .back {
    top: 20px;
    left: 23px;
    div {
      height: 15px;
      width: 15px;
    }
  }
  &:global {
    > form,
    > div:nth-child(2):not(.custom) {
      padding: 0;
      p {
        color: black;
        text-transform: none;
        font-weight: 400 !important;
        font-size: 12px;
      }
      .modalTitle {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        color: black;
        margin-bottom: 20px;
        margin-top: 35px;
        width: 100%;
      }
      h3 {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: black;
        width: 100%;
      }
      .bn-btn.primary {
        margin-top: auto;
        width: 100%;
        background-image: none;
        background-color: $bounce-blue;
        box-shadow: none;
      }
    }
  }
}

.tall-modal {
  height: 700px;
}

.hide-backbtn {
  :global {
    .modalTitle {
      margin-top: 8px !important;
    }
  }
}

.mobile-basic {
  @extend .defaultContent;

  width: 80%;
  height: 80%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  > div:nth-child(2):not(.custom),
  > form {
    width: 100%;
    min-height: 100%;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.autoHeight {
  @extend .defaultContent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: auto;
}

.mobile-p2p {
  @extend .mobile-basic;
  border-color: #3ecf8e;
}
.tablet-p2p {
  @extend .tablet-basic;
  border-color: #3ecf8e;
}
.desktop-p2p {
  @extend .desktop-basic;
  border-color: #3ecf8e;
}

.defaultConfirmation {
  outline: none;
  width: 300px;
  padding-top: 50px;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $content-br;
  border-top: $content-br solid $warning-border-color;

  :global {
    .confirmationMessage {
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        width: 80%;
      }
      margin-bottom: 20px;
    }
    .actions {
      width: 100%;
      background: #f6f6f6;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: flex-end;
    }
    .actions .button {
      margin-right: 10px;
    }
    .actions .button:last-child {
      min-width: 100px;
    }
  }

  &:global {
    &.success {
      border-top: $content-br solid green;
    }
  }
}

.mobile-confirmation {
  @extend .defaultConfirmation;
  width: 80%;
}

.tablet-confirmation {
  @extend .defaultConfirmation;
}

.desktop-confirmation {
  @extend .defaultConfirmation;
}

.share-url {
  @extend .defaultContent;
  border-top: $content-br solid $theme-color;
  border-radius: $content-br;
  width: 90%;
  max-width: 400px;
  background: white;
  padding-top: 50px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.photoUpload {
  width: 95%;
  height: 95%;
  @include tablet-portrait-up {
    width: 570px;
    max-height: 900px;
  }
  padding-top: 12px;
  padding-bottom: 15px;
  position: relative;
  border-top: $content-br solid $theme-color;
  outline: none !important;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: $content-br;
  :global {
    @import '../../../scss/crop';
  }
}
