.wrapper {
  display: flex;
  align-items: center;
  :global {
    .nav {
      cursor: pointer;
      transition: color 0.1s ease;
      color: white;
      font-size: 12px;
      margin: 0 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      &.hover:not(.active) {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
