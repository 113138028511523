.user {
  --primary-horizontal-padding: 30px;
  --secondary-horizontal-padding: 20px;
  --grid-gap: 16px;

  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper {
  margin: 0 !important;

  .cardHeader {
    padding: 10px var(--primary-horizontal-padding);
    display: flex;
    width: 100%;
  }
}

.userInfo {
  margin-bottom: var(--grid-gap) !important;
}

.coverPhoto {
  height: 200px;
  width: 100%;
  background: linear-gradient(269.62deg, #cae3ff 0.1%, #d7d3ff 100%);
}

.userDetails {
  display: flex;
  width: 100%;
  padding: 0 var(--secondary-horizontal-padding) 0
    var(--primary-horizontal-padding);

  .profilePic {
    margin-top: -28px;
    margin-right: 20px;
  }

  .headerRow {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-between;

    .fixedRow {
      --fixed-row-height: 40px;

      margin: 10px 0;
      height: var(--fixed-row-height);
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .userName {
        font-size: 20px;
      }

      .actionButton {
        border-radius: 15px;
        display: flex;
        align-items: center;

        &:not(:first-of-type) {
          margin-left: 12px;
        }
        .pendingRequestIcon {
          height: 20px;
          margin-right: 10px;
          margin-bottom: 3px;
        }
        i {
          margin-right: 10px;
        }
      }
    }

    .socialIconsRow {
      display: flex;

      .socialLink {
        height: 36px;
        width: 36px;
        border-radius: 50%;
        background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        cursor: pointer;

        img {
          height: 55%;
        }

        i {
          font-size: 16px;
        }
      }
    }

    .subtitleRow {
      span {
        color: #8a8a8e;
        margin-right: 10px;
      }
    }
  }
}

.mainContentGrid {
  display: grid;
  grid-template-rows: 152px 120px minmax(104px, 198px);
  grid-template-columns: 2fr minmax(300px, 1fr);
  gap: var(--grid-gap);

  .events {
    grid-row: span 3;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px auto minmax(104px, 198px);

    .events {
      grid-column: span 2;
      grid-row: span 1;
    }

    .galleryContainer {
      grid-column: span 2;
    }
  }

  > div {
    min-height: 0;

    .private,
    .empty {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.interests {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 var(--secondary-horizontal-padding);

  overflow-y: auto;

  .interest {
    background-color: #eaeaec;
    border-radius: 4px;
    padding: 10px 14px;
    margin-top: 10px;

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

.friends {
  width: 100%;
  padding: 0 var(--secondary-horizontal-padding);

  .viewMore {
    margin-top: 10px;
    color: #4a70ff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .friendList {
    display: flex;
    width: 100%;

    > div {
      height: 36px;
      width: 36px;
      background-color: #3a3d65;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border: solid 1.5px white;
    }

    > div:not(:first-of-type):not(.remainingFriends) {
      margin-left: -8px;
    }

    .friend {
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;

      img {
        height: 100%;
        width: auto;
      }

      &:hover {
        filter: grayscale(0.8);
      }
    }

    .remainingFriends {
      border-radius: 50%;
      background-color: #eaeaec;
      color: black;
      margin-left: 10px;
      cursor: default;
    }
  }
}

.galleryWrapper {
  width: 100%;
  height: 100%;
  padding: 0 var(--secondary-horizontal-padding);
}

.gallery {
  height: 100%;
  border: solid 1px #d4d4d4;
  border-radius: 4px;
  padding: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;

  .galleryPic {
    padding-top: 55%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;

    background-size: cover;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
