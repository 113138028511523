@use 'legacy/common/styles/colors';

.title {
  padding: 10px 10px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin: 0;
    font-size: 18px;

    font-weight: 600;
  }

  .clear {
    cursor: pointer;
    color: colors.$gray;

    &:hover {
      color: colors.$black;
    }
  }
}
