@use 'legacy/common/styles/colors';
@use 'legacy/common/styles/config.scss';

.button {
  width: 100%;
  height: 40px;

  border-radius: 16px;
  border: 1px solid colors.$primary;

  background: colors.$white;

  font-size: 14px;
  font-weight: bold;
  color: colors.$primary;

  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.primary {
    color: colors.$white;
    background: colors.$primary;
  }

  &.secondary {
    background: none;
    color: colors.$gray-300;
    border: 1px solid colors.$gray-300;
  }

  &.outline {
    background: none;
    color: colors.$white;
    border: 1px solid colors.$white;
  }

  &.clear {
    border: none;
    background: none;
    color: colors.$white;
  }

  &.text {
    width: auto;
    height: auto;
    border: none;
    background: none;
    font-size: 12px;
    color: colors.$primary;
  }

  &.danger {
    background: rgba(colors.$danger, 0.15);
    color: colors.$danger;
    border: 0px ;
  }

  &.inverted {
    background: colors.$primary-300;
    color: colors.$primary-700;
    border: 0;
    border-radius: 9999px; // Emulating rounded-full from Tailwind
    padding: 0 0.75rem;
    width: 100%;
  }

  &.largeCta {
    background: colors.$primary-800;
    border-radius: 0;
    color: colors.$white;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    width: 100%;
    column-gap: 8px;
    font-size: 14px;
  }

  &.small {
    height: 34px;
    font-size: 12px;

    border-radius: 12px;
  }

  &.large {
    height: 48px;
    font-size: 14px;
    padding: 0 40px;
    border-radius: 12px;
  }

  &.autoWidth {
    width: auto;
    padding: 0 16px;
    font-weight: 600;
  }

  &.newRadius {
    border-radius: 9999px; // Emulating rounded-full from Tailwind
  }

  &.rounded {
    align-items: center;
    border-radius: 48px;
    display: flex;
    justify-content: center;
    height: 48px;
    width: 48px;
  }

  &.fillContainer {
    height: 100%;
  }

  &.transparentBg {
    background: none;
  }

  @media (max-width: config.$md) {
    &.mobileRounded {
      border-radius: 9999px; // Emulating rounded-full from Tailwind
      width: auto;
    }

    &.largeCta {
      height: 56px;
    }
  }
}
