$theme-color: #6386fe;
$bounce-accent-1: #b721ff;
$bounce-accent-2: #21d4fd;
$bounce-std-linear-gradient: linear-gradient(
  212deg,
  $bounce-accent-1,
  $bounce-accent-2
);
$std-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
$bounce-blue: #4a70ff;
$text-color: #3a3d65;
$bg-color: #f8f8fb;
$card-color: #fefefe;
$light-border-color: #d6e3ff;
$footer-color: #f6f9fc;
$dark: #241940;

$desktop-min-width: 1000px;
$desktop-max-width: 1500px;

$tablet-min-width: 870px;
$tablet-max-width: 900px;

$mobile-breakpoint: 600px;
$tablet-breakpoint: 1000px;
$desktop-breakpoint: 1200px;
$xl-desktop-breakpoint: 1500px;

// Breakpoints
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xll: 1536px;

// use the following to implement scalable text and min/max for media queries

// $max-h1-font-size: 18px;
// $std-h1-font-size: 18px;
// $min-h1-font-size: 14px;

// going with absolute sizes instead
$std-h1-font-size: 13.5px;
$std-h2-font-size: 12px;
$std-p-font-size: 12px;
$std-subtext-font-size: 9px;

// Landing Pages

$bounce-gradient: linear-gradient(269.62deg, #4199fd 0.1%, #6d60fe 100%);
$bounce-gradient-alt: linear-gradient(276.34deg, #875bfb 0%, #6abdf7 100%);

$mobile-h1-font-size: 40px;
$mobile-h2-font-size: 30px;
$mobile-h3-font-size: 24px;
$mobile-p-font-size: 16px;
$mobile-subtext-font-size: 9px;
