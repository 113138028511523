.Dropdown {
  position: relative;
  :global {
    .dropdown-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .dropdown {
      display: none;
      position: absolute;
      &.open {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
