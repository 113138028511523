@import '~react-image-gallery/styles/css/image-gallery.css';

.image-gallery-description {
  position: absolute;
  width: 100%;
  bottom: 0 !important;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 4%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  text-align: left;
  font-size: 14px;
  min-height: 60px;
  height: auto !important;
  padding-top: 20px !important;
  line-height: 150% !important;
  word-wrap: break-word;
}
