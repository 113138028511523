@use 'legacy/common/styles/colors';

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 40px;
  padding: 5px 3px;
  margin: 20px 0;

  border-radius: 15px;

  background-color: colors.$primary-200;

  font-size: 10px;
  font-style: italic;
  text-align: center;

  img {
    margin-right: 5px;
  }
}
