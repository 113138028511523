@use 'legacy/common/styles/colors';

.container {
  padding: 10px 5px;
  max-height: 500px;

  .itemContainer {
    display: flex;
    &:hover {
      background: rgba(colors.$primary, 0.1);
    }
  }

  .list {
    .listItem {
      padding: 8px 5px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;
      border-radius: 5px;

      color: colors.$black;

      .pic {
        width: 40px;
        height: 40px;
        margin-right: 10px;

        border-radius: 100%;
        object-fit: cover;
        object-position: center;
      }

      .content {
        width: 100%;

        p {
          margin: 0;
          font-weight: 400;
          font-size: 16px;
          padding-left: 2px;
        }

        span {
          font-size: 12px;
          color: colors.$gray;
          padding-left: 2px;
        }
      }
    }
  }

  .loader {
    padding-top: 20px;
  }
}
