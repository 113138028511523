// ==========================================================================
// This file contains all legacy css.
// Avoid adding more css here. Eventually, this should be deleted as we
// refactor all the components in the web.
// ==========================================================================

@import '../../scss/variables';

// Global Modal Stuff
@import '../../scss/mixins';

$content-br: 6px;
$warning-border-color: #ec407a;
$delay: 350ms;
$slide-distance: 100px;
html body .iti-flag {
  background-image: transparent;
}
.modalTitle {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #3a3d65;
  margin-bottom: 20px;
}
.ReactModal__Overlay,
.ReactModal__Content {
  transition: all $delay ease;
}
.ReactModal__Overlay {
  &.transition-default {
    opacity: 0;
  }
  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }
  &.ReactModal__Overlay--before-close.transition-default {
    opacity: 0;
  }
}

.ReactModal__Content {
  .card {
    .number,
    .bn-row.info {
      font-size: 12px;
    }
    .type i {
      font-size: 42px;
      line-height: 30px;
      border-radius: 0px;
    }
    & > div {
      border-radius: 5px;
    }
  }

  box-shadow: none;
  transform: scale(0.5);
  &.transition-next {
    transform: scale(1) translateX($slide-distance);
    opacity: 0;
  }
  &.transition-prev {
    transform: scale(1) translateX(-$slide-distance);
    opacity: 0;
  }
  &.ReactModal__Content--after-open {
    box-shadow: 0px 20px 35px hsla(0, 0%, 0%, 0.2);
    transform: scale(1) translateX(0px);
    opacity: 1;
  }
  &.ReactModal__Content--before-close.transition-default {
    box-shadow: none;
    transform: scale(0.5);
  }
  &.ReactModal__Content--before-close.transition-next {
    transform: scale(1) translateX(-$slide-distance);
    opacity: 0;
  }
  &.ReactModal__Content--before-close.transition-prev {
    transform: scale(1) translateX($slide-distance);
    opacity: 0;
  }
}

// FIX: used in EditAgenda
.react-datepicker__portal {
  bottom: 0;
  height: 100% !important;
}

.popup {
  background-image: linear-gradient(180deg, #509bfd, #687efe) !important;
}
.popup:before {
  background: #6185fd !important;
}

html body {
  font-family: 'Inter', sans-serif !important;
  font-weight: 500;
  background: #ffffff;
  font-size: 12px;
  overflow-y: scroll;

  // some bs to override zoom styles
  min-width: 320px;
  min-height: 0px;

  .app-popup {
    background: white !important;
    background-image: none !important;
  }
  .app-popup:before {
    background: white !important;
  }

  .iti-flag {
    background-image: url('/lib/intlTelInput/img/flags.png');
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti-flag {
      background-image: url('/lib/intlTelInput/img/flags@2x.png');
    }
  }

  .bn-row {
    width: 100%;
    display: flex;
  }

  .col {
    display: flex;
    flex-direction: column;
  }

  // header links
  h1 {
    font-family: 'Inter', sans-serif !important;
    //font-size: 1.7vw;
    font-size: $std-h1-font-size;
  }
  // container titles, event/person titles
  h2 {
    font-family: 'Inter', sans-serif !important;
    // font-size: 1.2vw;
    margin: 0;
    font-size: $std-h2-font-size;
  }
  h3 {
    font-family: 'Inter', sans-serif;
    margin: 0;
  }
  // descriptions
  p {
    font-family: 'Inter', sans-serif !important;
    font-size: $std-p-font-size;
  }
  // dates and times in containers
  .subtext {
    font-family: 'Inter', sans-serif !important;
    font-size: $std-subtext-font-size;
  }

  @import '../../scss/_animations.scss';
  @import '../../scss/_common.scss';

  #root,
  #app {
    height: 100%;
    position: relative;
    > .container {
      height: 100%;
    }
  }
  a {
    text-decoration: none;
  }
  #app {
    .transition-group {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media only screen and (max-width: 1000px) {
        align-items: flex-start;
      }
    }
    *,
    *:after,
    *:before {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    input {
      &:focus {
        outline: none;
      }
    }

    // separate rules for mobile
    &.mobile {
      line-height: 14px;
      .transition-group {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        > div {
          min-width: 100%;
          max-width: 1400px;
        }
      }
      *,
      *:after,
      *:before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
      input {
        &:focus {
          outline: none;
        }
      }
    }
  }

  .app-wrapper {
    height: 100%;
    width: 100%;
  }

  #app-content {
    height: 100%;
    width: 100%;
  }
}
