.header {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2b2c40;
  width: 100% !important;
  padding: 20px 50px;
  margin-bottom: 0;
  font-size: 18px;

  img {
    width: 20px;
    height: 20px !important;
    margin-bottom: 0 !important;
  }
}

.exit {
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

.pics {
  // margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100% !important;
  padding: 0 50px;
  > div:last-of-type {
    margin-right: auto;
  }
  > div {
    padding: 6px;
    margin-bottom: 10px;
    cursor: pointer;

    // &:hover {
    //     opacity: 0.7;
    // }
  }
}

.preview {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;

  > div {
    width: 100%;
    height: 100%;
  }
}

.picture {
  z-index: 50;
  &:hover {
    opacity: 0.7;
  }
}

.removePic {
  position: absolute;
  z-index: 60;
  bottom: 10px;
  right: 10px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
  &:hover {
    background: rgb(190, 190, 190);
  }
}
