@import '../../../scss/variables';

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $text-color;
  border-radius: 50%;

  :global {
    i {
      color: white;
    }
  }
}
