@import '../../../scss/variables';

.input_row {
  display: flex;
  :global {
    input {
      margin: 0 !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .copy {
      color: white;
      background: $text-color;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      transition: all ease 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
