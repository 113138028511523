@import '../../../scss/animations';
@import '../../../scss/mixins';
@import '../../../scss/variables';

.headerContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.MaxWidth {
  display: flex;
  width: 100%;
  justify-content: space-between;
  list-style: none;
  max-width: 1920px;
  padding: 0 25px;
  margin: auto;

  @media (min-width: $md) {
    padding: 0 80px;
  }
}

.Navlinks {
  display: flex;
  margin-right: 57px;
}

.NavlinksUser {
  display: flex;
  margin-right: 90px;
}

.Dashboard {
  button {
    width: 110px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $bg-color;
    border: 1px solid $bounce-blue;
    border-radius: 16px;
    font-family: 'Inter', sans-serif;
    padding: 10px 0px;
    box-shadow: (0px 4px 50px rgba(0, 0, 0, 0.15));
    font-weight: 600;
    transition: box-shadow 0.3s;
    color: $bounce-blue;

    &.ButtonBlue {
      color: white;
      margin-left: 20px;
      background: $bounce-blue;
    }

    &.ButtonBounce {
      color: white;
      margin-left: 20px;
      border-color: white;
      background: $bounce-gradient-alt;
    }

    &:hover {
      box-shadow: (0px 4px 50px rgba(0, 0, 0, 0.3));
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
}

@media (max-width: 768px) {
  .DesktopOnly {
    display: none;
  }
}

.MobileMenu {
  height: 180px;
  border-radius: 10px;
  width: 90%;
  background-color: white;
  color: #6386fe;
  font-size: 13px;
  transition: fade-in 0.3s ease-out;
  position: absolute;
  list-style-type: none;
  box-shadow: 0 1px 24px 0.5px rgba(0, 0, 0, 0.08);
  z-index: 200;
  top: 80px;
  left: 0;
  right: 0;
  margin: 0 auto;

  ul {
    border-radius: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    background-color: white;

    li {
      padding: 15px 20px;
    }
  }
}

.NavMenu {
  :global {
    li {
      width: 100%;
      color: #6386fe !important;
    }
  }
}

@media (min-width: 768px) {
  .MobileMenu {
    display: none;
  }
}

.Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  color: #fafafa;
  z-index: 200;
  border: none;
  transition: 0.3s ease-in;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all ease-in 0.3s;
  }

  &.active {
    background: rgba(255, 255, 255, 0.85);

    .bg {
      opacity: 0;
    }
    a {
      > div {
        color: $bounce-blue;
      }
      > div:hover {
        color: #2c54d4 !important;
      }
    }
    :global {
      #underline {
        background: #6386fe;
      }
    }
  }
  :global {
    #login,
    #dashboard {
      i {
        transform: translateX(4px);
      }
      &:hover {
        i {
          animation: arrow-bounce-right 1.8s infinite;
        }
      }
    }
    .logo {
      display: flex;
      align-items: center;
      padding: 0 20px 0 0;
      .click-wrapper {
        cursor: pointer;
        img {
          width: 150px;
        }
      }
    }

    #underline {
      height: 3px;
      width: 0px;
      background: white;
      position: absolute;
      top: 42px;
      transition: left ease 0.4s;
    }
    .menu {
      width: 25px;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
        i {
          opacity: 0.7;
        }
      }
    }
    .actived {
      color: #6386fe;
    }
    @media (min-width: 768px) {
      .menu {
        display: none;
      }
    }
    .collapse {
      z-index: 1;
      margin-left: auto;
      margin-right: 5%;
      display: flex;

      &.collapsed {
        &.login {
          color: #6386fe;
        }
        .menu {
          display: flex;
        }
      }
    }
  }
  .menu {
    position: absolute;
    top: 15px;
    font-size: 18px;
    right: 20px;
  }
  .collapse {
    display: none;
    margin-right: 15px;
    &.collapsed {
      position: relative;
      margin-left: auto;

      .slide-wrapper {
        position: fixed;
        left: 100%;
        top: 50px;
        width: 90%;
        height: 260px;
        &.open {
          left: 20px;
          right: 20px;
          z-index: 100;
        }
      }
      // .bn-row {
      //     font-size: 14px !important;
      //     position: absolute;
      //     overflow: hidden;
      //     z-index: 100;
      //     transition: all ease 0.3s;
      //     display: block;
      //     opacity: 0;
      //     background: white;
      //     left: 100%;
      //     right: 0;
      //     top: 0;
      //     bottom: 0;
      //     &.open {
      //         opacity: 1;
      //         left: 0;
      //         box-shadow: 0 1px 24px 0.5px rgba(0, 0, 0, 0.08);
      //         border-radius: 5px;
      //     }
      //     .nav {
      //         height: 40px;
      //         display: flex;
      //         align-items: center;
      //         padding: 0 20px;
      //         margin-bottom: 1px;
      //     }
      // }
    }
  }
  //     }
  // }
}

.NavBar {
  display: flex;
}

.Auth {
  display: flex;
}
