@use 'legacy/common/styles/config';
@use 'legacy/common/styles/colors';

.container {
  padding: 10px 10px;
}

.group {
  margin: 20px 0;
}

.relative {
  position: relative;
}

.label {
  margin: 10px 0;
  padding: 0;
  font-size: 14px;
  text-align: left;
  color: colors.$gray-300;
  font-weight: 400;

  &.small {
    font-size: 12px;
  }
}

.inputMargin {
  margin-left: 10px;
}

.input {
  width: 100%;
  height: 40px;
  border: none;
  padding: 16px;
  font-size: 16px;

  background: #ffffff;
  border: 1px solid #dcdcdd;
  border-radius: 16px;

  &::placeholder {
    opacity: 0.4;
    color: #333333;
    font-style: italic;
  }

  &:disabled {
    cursor: not-allowed;
    background: colors.$gray-100;
    opacity: 0.7;
  }

  &.margin {
    margin-left: 10px;
  }

  @media (min-width: config.$md) {
    font-size: 14px;
  }
}

.inputIcon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-16px, 8px);
}

.textarea {
  width: 100%;
  border: none;
  padding: 16px;
  font-size: 16px;

  background: #ffffff;
  border: 1px solid #dcdcdd;
  border-radius: 16px;

  &::placeholder {
    opacity: 0.4;
    color: #333333;
    font-style: italic;
  }

  @media (min-width: config.$md) {
    font-size: 14px;
  }
}

.button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: #6586ff;
  border-radius: 16px;
  background: #ffffff;
  border: 1px solid #6586ff;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 0.12px;
  margin: 0 auto;

  opacity: 0.99; // Hack to support overflow hidden in webview
  overflow: hidden;

  &.Primary {
    color: #ffffff;
    background: #6586ff;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  width: 48%;
}

.error {
  display: flex;
  font-size: 12px;
  margin-top: 4px;
  color: colors.$error;
  justify-content: flex-start;

  &.right {
    justify-content: flex-end;
  }
}

.submitError {
  font-size: 12px;
  margin-top: 4px;
  color: colors.$error;
}

.errorField {
  border: 1px solid colors.$error !important;
}

.formError {
  font-size: 12px;
  margin-top: 4px;
  color: colors.$error;
  text-align: center;
}

.separator {
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    width: 40%;
    height: 0;
    display: block;
    border-top: solid 1px #6586ff;
  }
  div {
    color: #6586ff;
  }
}

.phoneContainer {
  width: 100%;
}

.codeInputContainer {
  font-size: 28px;
  margin-top: 10px;
  label {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
  }
}

.codeInput {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;

  div {
    display: flex;
    justify-content: space-between;
    input {
      border: 1px solid #dcdcdd !important;
      border-radius: 16px !important;
      width: 50px !important;
      height: 40px !important;

      &::placeholder {
        color: '#333333';
        opacity: 0.4;
      }

      @media (min-width: config.$md) {
        width: 58px !important;
      }
    }
  }
}

.actionItem {
  color: #6586ff;
  cursor: pointer;
}

.select {
  width: 100%;

  border: none;
  height: 40px;
  padding: 0 14px;
  font-size: 16px;

  background: #ffffff;
  border: 1px solid #dcdcdd;
  border-radius: 16px;

  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &::placeholder {
    opacity: 0.4;
    color: #333333;
    font-style: italic;
  }

  @media (min-width: config.$md) {
    font-size: 14px;
  }
}

.image {
  width: 96px;
  height: 96px;
  background: #b4b4b4;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .photo {
    width: 96px;
    height: 96px;
    border-radius: 50%;
  }
}
